<template>
  <div v-if="(!hi)">Loading Please wait...
  </div>
  <div v-else>
    <b-form>

      <b-row>
        <b-col cols="12" md="12">
          <blockquote>
            <b>Information</b><br>
            Hinzurechnungen erhöhen die Errungenschaft. Unentgeltliche Zuwendungen können bis fünf Jahre rückwirkend geltende gemacht werden. Vermögensentäusserungen mit dem Zweck der Schmälerung der Errungenschaft der anderen Partei verjähren hingegen nicht.
        </blockquote>
        </b-col>
      </b-row>

      <b-table
        ref="tblHR"
        selectable
        select-mode="single"
        striped
        hover
        responsive
        class="position-relative mt-1"
        :items="hi"
        primary-key="HinzuID"
        selected-variant="primary"
        :fields="fieldsHinzu"
        @row-selected="showHinzu"
      >
        <template #row-details="row">

          <b-row>
            <b-col cols="12" md="3">
              <b-form-group
                label="Hinzurechnung"
                label-for="hinzuBez"
              >
                <b-form-input
                  v-model="row.item.Bezeichnung"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="2">
              <b-form-group
                label="Datum"
                label-for="datHinzu"
              >
                <flat-pickr
                  id="datumHinzu"
                  v-model="row.item.Datum"
                  :config="config"
                  class="form-control"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="2">
              <b-form-group label-align="right"
                label="Betrag"
                label-for="hinzuBetrag"
              >
                <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="row.item.Betrag"
                  @blur="applyFormat($event, row, 'Betrag')"
                  @focus="removeFormat"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="2">
              <b-form-group
                :label="'Errungenschaft ' + $root.VNameFrau"
                label-for="istErrungenFrau"
              >
                <b-form-checkbox
                  v-model="row.item.IstErrungenFrau"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="2">
              <b-form-group
                label="Unentgeltliche Zuwendung"
                label-for="unentgZuwend"
              >
                <b-form-checkbox
                  v-model="row.item.IstZuwendung"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="1 text-right">
              <b-button
                v-show="row.item.HinzuID > 0"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="outline-primary"
                class="btn-icon mt-2"
                @click="deleteHinzu(row.item)"
              >
                <feather-icon icon="XCircleIcon" />
              </b-button>
            </b-col>

          </b-row>

          <b-row>
            <b-col cols="12" md="12">
              <b-form-group
                label="Beschreibung"
                label-for="hinzuBeschrieb"
              >
                <b-form-textarea
                  v-model="row.item.Beschrieb"
                  rows="2"
                  max-rows="3"
                />
              </b-form-group>
            </b-col>

          </b-row>

        </template>

      </b-table>

      <b-button
          variant="primary"
          class="mt-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="speichernHinzu"
        >
          Speichern
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="mt-1 ml-1"
        @click="addHinzu"
      >
        <feather-icon
          icon="PlusCircleIcon"
          class="mr-50"
        />
        <span class="align-middle">Weitere Hinzurechnung</span>
      </b-button>

    </b-form>

  </div>
</template>

<script>

import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BFormTextarea, BForm, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import VueSlider from 'vue-slider-component'
import flatPickr from 'vue-flatpickr-component'
import { German } from 'flatpickr/dist/l10n/de'
import { French } from 'flatpickr/dist/l10n/fr'
import gut from '@/service/gut'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BForm,
    BFormInput,
    BFormCheckbox,
    flatPickr,
    BTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      hi: null,
      isEditing: false,
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'j M, Y',
        altInput: true,
        // dateFormat: 'd.m.Y',
        locale: this.$g.user.Sprache === 'fr' ? French : German,
      },
      fieldsHinzu: [
        { key: 'DatumTbl', label: 'Datum', sortable: true },
        { key: 'Bezeichnung', label: 'Hinzurechnung', sortable: true },
        { key: 'Betrag', formatter: value => { return value === undefined || value === null ? 0 : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'") }, label: 'Betrag', sortable: true }, // eslint-disable-line
      ],
      currRowHinzu: null,
    }
  },
  async created() {
    this.loadHinzu(-1)
  },
  methods: {
    formatNumber(value) {
      if (!this.isEditing && typeof value === 'string') {
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
        return value
      }
      return false
    },
    formatBetrag(val) {
      let v = val.replaceAll(/[’']/g, '')
      v = Intl.NumberFormat('de-CH').format(v)
      return v
    },
    applyFormat(event, row, value) { // eslint-disable-line
      event.target.value = (event.target.value || '0').replace(/^0+(?!$)/, '').replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
      let ix = 0
      for (; ix < this.hi.length; ix++) if (this.hi[ix].BedarfID === row.item.BedarfID) break // eslint-disable-line
      this.hi[ix][value] = event.target.value
      this.isEditing = false
    },
    removeFormat(event) {
      this.isEditing = true
      event.target.value = event.target.value.replace(/[^\d]/g, '') // eslint-disable-line
      if (event.target.value === '0') event.target.value = '' // eslint-disable-line
    },

    async loadHinzu(i2Show) {
      const rh = await gut.getHinzurechnung(this.$root.currDosID, this.$g.user.Sprache)
      if (rh.data.length > 0) {
        for (let i = 0; i < rh.data.length; i += 1) rh.data[i]._showDetails = (i === i2Show) // eslint-disable-line
        this.hi = rh.data
      }
    },
    async showHinzu(items) {
      if (items.length > 0) this.currRowHinzu = items[0]
      this.currRowHinzu._showDetails = !this.currRowHinzu._showDetails  // eslint-disable-line
    },
    addHinzu() {
      const np = JSON.parse(JSON.stringify(this.hi)).slice(0, 1)
      np[0].HinzuID = -(this.hi.length + 1)
      np[0].DosID = this.$root.currDosID
      np[0].Bezeichnung = ''
      np[0].Datum = null
      np[0].Betrag = 0
      np[0].IstErrungenFrau = 0
      np[0].IstZuwendung = 1
      np[0].Beschrieb = ''
      np[0]._showDetails = true // eslint-disable-line
      this.hi.push(np[0])
      this.currRowHinzu = this.hi[this.hi.length - 1]
    },
    async speichernHinzu() {
      await gut.putHinzurechnung(this.hi)
    },
    async deleteHinzu(item) {
      if (await this.$root.msgBoxConfirm('Soll HInzurechnung wirklich gelöscht werden?')) {
        await gut.delHinzurechnung(item.HinzuID)

        this.loadHinzu(-1)

        this.$root.showToast('Hinzurechnung', 'Hinzurechnung ' + item.Bezeichnung + ' wurde erfolgreich gelöscht!')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
