import axios from '@axios'

export default {
  async getGut(dosID, vnFrau, vnMann) {
    try {
      const r = await axios.get('gut/getGut', {
        params: {
          dosID,
          vnFrau,
          vnMann,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },

  async getGutSel(dosID, eigentum) {
    try {
      const r = await axios.get('gut/getGutSel', {
        params: {
          dosID,
          eigentum,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },

  async getGutAusgleich(dosID) {
    try {
      const r = await axios.get('gut/getGutAusgleich', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },

  async putGutAusgleich(data) {
    try {
      const d = JSON.parse(JSON.stringify(data))
      for (let i = 0; i < d.length; i += 1) {
        d[i].BetragChange = (d[i].BetragChange ?? '0').toString().replaceAll(/[’']/g, '')
      }
      const r = await axios.get('gut/getGutAusgleichInsUpd', {
        params: {
          data: JSON.stringify(d),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },

  async putGut(data) {
    try {
      const d = JSON.parse(JSON.stringify(data))
      for (let i = 0; i < d.length; i += 1) {
        d[i].Betrag = (d[i].Betrag ?? '0').toString().replaceAll(/[’']/g, '')
      }
      const formData = new FormData()
      formData.append('data', JSON.stringify(d))
      const r = await axios.post('gut/postGutInsUpd', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      // {
        // params: {
        //   data: JSON.stringify(d),
        // },
      })
      return r
    } catch (error) {
      // if (error.response) {
      //   console.log('response')
      //   console.log(error.response.data)
      //   console.log(error.response.status)
      //   console.log(error.response.headers)
      // } else if (error.request) {
      //   console.log('request')
      //   console.log(error.request)
      // } else {
      //   console.log('message')
      //   console.log('Error', error.message)
      // }
      // console.log('config')
      // console.log(error.config)
      // console.log(error)
      this.errored = true
    }
    return null
  },

  async delGut(dosID, gutID, dosBelegID) {
    try {
      const r = await axios.get('gut/getGutDelete', {
        params: {
          dosID,
          gutID,
          dosBelegID: dosBelegID ?? 0,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },

  async delGutFluss(gutFlussID) {
    try {
      const r = await axios.get('gut/getGutFlussDelete', {
        params: {
          gutFlussID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },

  async getLiegenschaft(liegenschaftID, dosID) { // WEF is in vorsorge.js...
    try {
      const r = await axios.get('gut/getLiegenschaft', {
        params: {
          liegenschaftID,
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putLiegenschaft(data, gutID) { // When new record, updates Gut in SPROC with LiegenschaftID...
    try {
      const d = JSON.parse(JSON.stringify(data))
      d.Kaufpreis = (d.Kaufpreis ?? '0').toString().replaceAll(/[’']/g, '')
      d.Abgaben = (d.Abgaben ?? '0').toString().replaceAll(/[’']/g, '')
      const r = await axios.get('gut/getLiegenschaftInsUpd', {
        params: {
          data: JSON.stringify(d),
          gutID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },

  async getGutFluss(gutID, sprache) {
    try {
      const r = await axios.get('gut/getGutFluss', {
        params: {
          gutID,
          sprache,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putGutFluss(data) {
    try {
      const d = JSON.parse(JSON.stringify(data))
      for (let i = 0; i < d.length; i += 1) {
        d[i].Betrag = (d[i].Betrag ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].Rueckzahlung = (d[i].Rueckzahlung ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].AnteilEF = (d[i].AnteilEF ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].AnteilRF = (d[i].AnteilRF ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].AnteilEM = (d[i].AnteilEM ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].AnteilRM = (d[i].AnteilRM ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].AnteilME = (d[i].AnteilME ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].AnteilLast1 = (d[i].AnteilLast1 ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].AnteilLast2 = (d[i].AnteilLast2 ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].AnteilWEF = (d[i].AnteilWEF ?? '0').toString().replaceAll(/[’']/g, '')
      }
      const r = await axios.get('gut/getGutFlussInsUpd', {
        params: {
          data: JSON.stringify(d),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },

  async getHinzurechnung(dosID, sprache) {
    try {
      const r = await axios.get('gut/getHinzurechnung', {
        params: {
          dosID,
          sprache,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putHinzurechnung(data) {
    try {
      const d = JSON.parse(JSON.stringify(data))
      for (let i = 0; i < d.length; i += 1) {
        d[i].Betrag = (d[i].Betrag ?? '0').toString().replaceAll(/[’']/g, '')
      }
      const r = await axios.get('gut/getHinzurechnungInsUpd', {
        params: {
          data: JSON.stringify(d),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async delHinzurechnung(hinzuID) {
    try {
      const r = await axios.get('gut/getHinzurechnungDelete', {
        params: {
          hinzuID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },

  async calcVorschlag(dosID, sprache) {
    try {
      const r = await axios.get('gut/getGueterrecht', {
        params: {
          dosID,
          sprache,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },

  async getGueterrecht(dosID) {
    try {
      const r = await axios.get('gut/getGueterrecht', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },

  async putGueterrechtAusgleich(dosID, ausgleichDiff, konvention) {
    try {
      const r = await axios.get('gut/getGueterrechtAusgleichUpd', {
        params: {
          dosID,
          ausgleichDiff,
          konvention: konvention === null ? '' : konvention,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },

  async getGutPdf(dosID, gutID) {
    try {
      const r = await axios.get('gut/getGutPdf', {
        responseType: 'blob',
        headers: { Accept: 'application/pdfime' },
        params: {
          dosID,
          gutID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },

}
