<template>
  <div v-if="!gu">Loading Please wait...
  </div>
  <div v-else>
    <b-form>
      <b-table
        ref="tblGU"
        selectable
        select-mode="single"
        :current-page="currPage"
        :per-page="pageRows"
        striped
        hover
        responsive
        class="position-relative"
        :items="gu"
        primary-key="GutID"
        :fields="fieldsGut"
        selected-variant="primary"
        @row-selected="showGut"
      >
        <template #row-details="row">

          <b-row>
            <b-col cols="12" md="5">
              <b-form-group
                label="Vermögenswert/Schuld"
                label-for="gut"
              >
                <b-form-input
                  v-model="row.item.Gut"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3">
              <b-form-group
                label="Eigentum"
                label-for="typ"
              >
                <v-select
                  v-model="row.item.Eigentum"
                  :options="eigentum"
                  :reduce="val => val.value"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="2">
              <b-form-group label-align="right"
                label="Aktueller Wert"
                label-for="vorbezugBetrag"
              >
                <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="row.item.Betrag"
                  @blur="applyFormatGU($event, row, 'Betrag')"
                  @focus="removeFormat"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="1">
              <b-form-group
                label="Schuld?"
                label-for="schuld"
              >
                <b-form-checkbox
                  v-model="row.item.IstSchuld"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="1 text-right">
              <b-button
                v-show="row.item.GutID > 0"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.top="'Gut löschen?'"
                size="sm"
                variant="outline-primary"
                class="btn-icon mt-2"
                @click="deleteGut(row.item)"
              >
                <feather-icon icon="XCircleIcon" />
              </b-button>
            </b-col>

          </b-row>

          <b-row>
            <b-col cols="12" md="8">
              <b-form-group
                label="Bemerkung"
                label-for="bemBeleg"
              >
                <b-form-textarea
                  v-model="row.item.Bemerkung"
                  rows="2"
                  max-rows="3"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3" v-show="row.item.IstSchuld">
              <b-form-group
                label="Belasteter Vermögenswert"
                label-for="label"
              >
                <v-select
                  v-model="row.item.LastGutID"
                  :options="gus"
                  :reduce="val => val.value"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>

          </b-row>

          <b-row align-h="end">

              <b-button v-show="row.item.GutID > 0"
              variant="primary"
              class="ml-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="handleBeleg(belegTyp.Einlage, row.item)"
              >
                Beleg
              </b-button>

              <!-- <b-button v-show="row.item.GutID > 0 && row.item.Eigentum !== 'me'" -->
              <b-button v-show="row.item.GutID > 0"
                variant="primary"
                class="ml-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="handleErweitert(row.item)"
              >
                  Erweitert
              </b-button>

              <!-- <b-button v-show="row.item.GutID > 0 && row.item.Eigentum !== 'me'" -->
              <b-button v-show="row.item.GutID > 0"
                variant="primary"
                class="ml-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="handleLiegenschaft(row.item)"
              >
                Immobilie
              </b-button>

          </b-row>

        </template>

      </b-table>
      <div>
        <b-pagination
          v-model="currPage"
          :total-rows="gu.length"
          :per-page="pageRows"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>

      <b-button
          variant="primary"
          class="mt-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="speichernGut"
        >
          Speichern
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="mt-1 ml-1"
        @click="addGut"
      >
        <feather-icon
          icon="PlusCircleIcon"
          class="mr-50"
        />
        <span class="align-middle">Weiterer Wert</span>
      </b-button>

    </b-form>

    <b-modal
      :id="modalIDBeleg"
      title="Beleg"
      cancel-title="Abbrechen"
      ok-title="Speichern"
      cancel-variant="outline-secondary"
      size="lg"
      centered
      no-close-on-backdrop
      @ok="speichernBeleg"
    >
      <div v-if="!be">
      </div>
      <div v-else>
        <b-row>
            <b-col cols="12" md="9 mt-1">
              <b-form-file
                v-model="file"
                accept=".pdf"
                :placeholder="be === null || be.DateiName === null ? 'Antwortbeleg auswählen oder hier ablegen...' : be.DateiName"
                drop-placeholder="Datei hier ablegen..."
              />
              <small
                v-show="file !== null && file.size > maxFileSize"
                class="text-danger"
              >{{ $g.browserLocale === 'fr' ? 'La grandeur du ficher ne doit pas depasser 500 KB!' : 'Die Datei darf nicht grösser als 500 KB sein!' }}</small>
            </b-col>

            <b-col cols="12" md="3 mt-1 text-right">
              <b-button
                variant="primary"
                :disabled="currRow.DosBelegID === null"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="downloadBeleg"
              >
                Herunterladen
              </b-button>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="9 mt-1">
              <b-form-group
                label="Bemerkung"
                label-for="bemBeleg"
              >
                <b-form-textarea
                  v-model="be.Bemerkung"
                  rows="2"
                  max-rows="4"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3 mt-1 text-right">
              <b-button
                variant="primary"
                :disabled="currRow.DosBelegID === null"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="deleteBeleg"
              >
                Löschen
              </b-button>
            </b-col>
          </b-row>

        </div>
    </b-modal>

    <b-modal
      :id="modalLiegenschaft"
      title="Liegenschaft"
      cancel-title="Abbrechen"
      ok-title="Speichern"
      cancel-variant="outline-secondary"
      size="lg"
      centered
      no-close-on-backdrop
      @ok="speichernLiegenschaft"
    >
      <div v-if="!lg">
      </div>
      <div v-else>
        <b-row>
            <b-col cols="12" md="6">
              <b-form-group
                label="Grundstück-Nr"
                label-for="lgGrundstueck"
              >
                <b-form-input
                  v-model="lg.GrundStueckNr"
                  trim
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group
                label="Grundbuchamt"
                label-for="grundbuch"
              >
                <b-form-input
                  v-model="lg.Grundbuchamt"
                  trim
                />
              </b-form-group>
            </b-col>

          </b-row>

          <b-row>
            <b-col cols="12" md="4">
              <b-form-group
                label="Kaufdatum"
                label-for="datWEFObj"
              >
                <flat-pickr
                  id="datWEFObj"
                  v-model="lg.Gekauft"
                  :config="config"
                  class="form-control"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
                  <b-form-group
                    label="Kaufpreis" label-align="right"
                    label-for="kaufpreis"
                  >
                    <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="lg.Kaufpreis"
                      @blur="applyFormatLG($event, 'Kaufpreis')"
                      @focus="removeFormat"
                    />
                  </b-form-group>
              </b-col>
              <b-col cols="12" md="4">
                  <b-form-group
                    label="Abgaben bei Verkauf" label-align="right"
                    label-for="abgabenVerkauf"
                  >
                    <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="lg.Abgaben"
                      @blur="applyFormatLG($event, 'Abgaben')"
                      @focus="removeFormat"
                    />
                  </b-form-group>
              </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6">
              <b-form-group
                label="Adresse"
                label-for="adresse"
              >
                <b-form-textarea
                  v-model="lg.Adresse"
                  rows="2"
                  max-rows="3"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group
                label="Beschrieb"
                label-for="lgBeschrieb"
              >
                <b-form-textarea
                  v-model="lg.Beschrieb"
                  rows="2"
                  max-rows="3"
                />
              </b-form-group>
            </b-col>

          </b-row>

        </div>
    </b-modal>

    <!-- :title="'Erweiterte Transaktionen ' + currRow.Gut" -->
    <b-modal
      :id="modalErweitert"
      title="Erweiterte Transaktionen"
      cancel-title="Abbrechen"
      ok-title="Schliessen"
      cancel-variant="outline-secondary"
      size="xl"
      centered
      no-close-on-backdrop
      @ok="speichernErweitertSchliessen"
    >
      <div v-if="!ge">
      </div>
      <div v-else>

        <b-row v-show="ge !== null && ge.length > 0 && ge[0].GutFlussID !== null">
          <b-col cols="12">
            <div class="text-right">
              <b-overlay :show="isCalc" no-wrap></b-overlay>  <!-- obscures row content, otherwise enclose content to be obscured... -->
              <b-button
                variant="primary"
                class="ml-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="showGutPdf"
              >
                  Berechnung anzeigen
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-table
            ref="tblGE"
            selectable
            select-mode="single"
            striped
            hover
            responsive
            class="position-relative mt-1"
            :items="ge"
            primary-key="GutFlussID"
            :fields="fieldsGutFluss"
            selected-variant="primary"
            @row-selected="showGutErweitert"
          >
            <template #row-details="row">
              <b-row>
                <b-col cols="12" md="12">
                  <blockquote>
                    <b>Information</b><br>
                    Erfassung Wertanteile aus verschiedenen Gütermassen, Investitionen, Rückzahlungen, Amortisationen, Eigengutzuweisung Kapitalleistungen, etc.<br>
                    Bei Amortisation Hypothek müssen Rückzahlung, Hypothek sowie die finanzierenden Gütermassen erfasst werden.
                </blockquote>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="3" v-show="currRow.LiegenschaftID === null">
                  <b-form-group
                    label="Aktion"
                    label-for="gutFluss"
                  >
                    <v-select
                      v-model="row.item.FlussArt"
                      :options="flussArt"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="3" v-show="currRow.LiegenschaftID !== null">
                  <b-form-group
                    label="Aktion"
                    label-for="gutFlussWEF"
                  >
                    <v-select
                      v-model="row.item.FlussArt"
                      :options="flussArtWEF"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="3">
                  <b-form-group
                    label="Datum"
                    label-for="datTrans"
                  >
                    <flat-pickr
                      id="datTrans"
                      v-model="row.item.Datum"
                      :config="config"
                      class="form-control"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="3">
                  <b-form-group
                    label="Beschrieb Aktion"
                    label-for="bez"
                  >
                    <b-form-input
                      v-model="row.item.Beschrieb"
                    />
                  </b-form-group>
                </b-col>

              </b-row>

              <b-row>
                <b-col cols="12" md="2" v-show="row.item.FlussArt !== null && !row.item.FlussArt.startsWith('kl')">
                  <b-form-group label-align="right"
                    label="Wert"
                    label-for="Wert"
                  >
                  <!-- <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="row.item.Betrag" -->
                    <b-form-input v-facade="'########'" class="text-right" v-model="row.item.Betrag"
                      @blur="setBarwert(row.item, currRow.Eigentum.endsWith('f'))"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2" v-show="row.item.FlussArt !== null && row.item.FlussArt.startsWith('kl')">
                  <b-form-group label-align="right"
                    label="Rente p.a."
                    label-for="Wert"
                  >
                  <!-- <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="row.item.Betrag" -->
                    <b-form-input v-facade="'########'" class="text-right" v-model="row.item.Betrag"
                      @blur="setBarwert(row.item, currRow.Eigentum.endsWith('f'))"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2" v-show="row.item.FlussArt !== null && row.item.FlussArt.startsWith('kl') && currRow.Eigentum.endsWith('f')">
                  <b-form-group label-align="right"
                    :label="'Eigengut ' + $root.VNameFrau"
                    label-for="betrag"
                  >
                    <b-form-input v-facade="'-?########'" :formatter="formatNumber" class="text-right" v-model="row.item.AnteilEF"
                      @blur="applyFormat($event, row, 'AnteilEF')"
                      @focus="removeFormat"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2" v-show="row.item.FlussArt !== null && row.item.FlussArt.startsWith('kl') && currRow.Eigentum.endsWith('m')">
                  <b-form-group label-align="right"
                    :label="'Eigengut ' + $root.VNameMann"
                    label-for="betrag"
                  >
                    <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="row.item.AnteilEM"
                      @blur="applyFormat($event, row, 'AnteilEM')"
                      @focus="removeFormat"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2" v-show="row.item.FlussArt === 'rza' || row.item.FlussArt === 'rzd'">
                  <b-form-group label-align="right"
                    label="Rückzahlung"
                    label-for="Wert"
                  >
                    <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="row.item.Rueckzahlung"
                      @blur="applyFormat($event, row, 'Rueckzahlung')"
                      @focus="removeFormat"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2" v-show="row.item.FlussArt !== null && !row.item.FlussArt.startsWith('kl')">
                  <b-form-group
                    label="Wertänderung konjunkturell?"
                    label-for="istKonjunkturell"
                  >
                    <b-form-checkbox
                      v-model="row.item.IstKonjunkturell"
                      class="custom-control-primary text-center"
                      name="check-button"
                      switch
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2" v-show="row.item.Last1 !== null">
                  <b-form-group label-align="right"
                    :label="row.item.Last1"
                    label-for="lastAnteil1"
                  >
                    <!-- <b-form-input v-facade="'-?########'" :formatter="formatNumber" class="text-right" v-model="row.item.AnteilLast1"
                      @blur="last1Changed(row.item)"
                    /> -->
                    <b-form-input v-facade="'-?########'" :formatter="formatNumber" class="text-right" v-model="row.item.AnteilLast1"
                      @blur="applyFormat($event, row, 'AnteilLast1')"
                      @focus="removeFormat"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2" v-show="row.item.Last2 !== null">
                  <b-form-group label-align="right"
                    :label="row.item.Last2"
                    label-for="lastAnteil2"
                  >
                    <b-form-input v-facade="'-?########'" :formatter="formatNumber" class="text-right" v-model="row.item.AnteilLast2"
                      @blur="applyFormat($event, row, 'AnteilLast2')"
                      @focus="removeFormat"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2" v-show="currRow.LiegenschaftID !== null && (row.item.FlussArt === 'inv' || row.item.FlussArt === 'rzw')">
                  <b-form-group label-align="right"
                    label="WEF"
                    label-for="anteilWEF"
                  >
                    <b-form-input v-facade="'-?########'" :formatter="formatNumber" class="text-right" v-model="row.item.AnteilWEF"
                      @blur="applyFormat($event, row, 'AnteilWEF')"
                      @focus="removeFormat"
                    />
                  </b-form-group>
                </b-col>

              </b-row>

              <b-row v-show="row.item.FlussArt !== null && !row.item.FlussArt.startsWith('kl')">
                <b-col cols="12" md="2">
                  <b-form-group label-align="right"
                    :label="'Eigengut ' + $root.VNameFrau"
                    label-for="betrag"
                  >
                    <b-form-input v-facade="'-?########'" :formatter="formatNumber" class="text-right" v-model="row.item.AnteilEF"
                      @blur="applyFormat($event, row, 'AnteilEF')"
                      @focus="removeFormat"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2">
                  <b-form-group label-align="right"
                    :label="'Errungenschaft ' + $root.VNameFrau"
                    label-for="betrag"
                  >
                    <b-form-input v-facade="'-?########'" :formatter="formatNumber" class="text-right" v-model="row.item.AnteilRF"
                      @blur="applyFormat($event, row, 'AnteilRF')"
                      @focus="removeFormat"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2">
                  <b-form-group label-align="right"
                    :label="'Eigengut ' + $root.VNameMann"
                    label-for="betrag"
                  >
                    <b-form-input v-facade="'-?########'" :formatter="formatNumber" class="text-right" v-model="row.item.AnteilEM"
                      @blur="applyFormat($event, row, 'AnteilEM')"
                      @focus="removeFormat"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2">
                  <b-form-group label-align="right"
                    :label="'Errungenschaft ' + $root.VNameMann"
                    label-for="betrag"
                  >
                    <b-form-input v-facade="'-?########'" :formatter="formatNumber" class="text-right" v-model="row.item.AnteilRM"
                      @blur="applyFormat($event, row, 'AnteilRM')"
                      @focus="removeFormat"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2">
                  <b-form-group label-align="right"
                    label="Gemeinsame Errungenschaft"
                    label-for="betrag"
                  >
                    <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="row.item.AnteilME"
                      @blur="applyFormat($event, row, 'AnteilME')"
                      @focus="removeFormat"
                    />
                  </b-form-group>
                </b-col>

              </b-row>

              <b-row v-show="currRow.Eigentum != 'me' && row.item.FlussArt !== null && !row.item.FlussArt.startsWith('kl')">

                <b-col cols="12" md="2">
                  <b-form-group
                    label="Entgeltlich Eigengut"
                    label-for="istEntgelt"
                  >
                    <b-form-checkbox
                      v-model="row.item.IstEntgeltlichE"
                      class="custom-control-primary text-center"
                      name="check-button"
                      switch
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2">
                  <b-form-group
                    label="Entgeltlich Errungenschaft"
                    label-for="istEntgelt"
                  >
                    <b-form-checkbox
                      v-model="row.item.IstEntgeltlichR"
                      class="custom-control-primary text-center"
                      name="check-button"
                      switch
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="3">
                  <b-form-group
                    label="Verteilung Wertänderung"
                    label-for="transGM"
                  >
                    <v-select
                      v-model="row.item.TransGM"
                      :options="transGM"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>

              </b-row>

            </template>

            <template v-slot:cell(DeleteGutFluss)="row">
              <b-button
                :disabled="row.item.GutFlussID <= 0"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.top="'Transaktion löschen?'"
                size="sm"
                variant="outline-primary"
                class="btn-icon"
                @click="deleteGutFluss(row.item)"
              >
                <feather-icon icon="XCircleIcon" />
              </b-button>
            </template>

          </b-table>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mt-1 ml-1"
            @click="addGutFluss"
          >
            <feather-icon
              icon="PlusCircleIcon"
              class="mr-50"
            />
            <span class="align-middle">Weitere Transaktion</span>
          </b-button>

          <b-button
            variant="primary"
            class="mt-1"
            style="float: right;"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="speichernErweitert"
          >
            Speichern
        </b-button>

        </div>
    </b-modal>

    <div v-show="false">
      <pdf-viewer
        ref="pv"
        :modal-i-d="modalID"
        :pdf-in="pdfIn"
        :file-name="dateiName"
      />
    </div>

  </div>
</template>

<script>

import Vue from 'vue'

import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BFormFile, BFormTextarea, BTable, VBTooltip, ModalPlugin, BPagination, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import VueSlider from 'vue-slider-component'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { German } from 'flatpickr/dist/l10n/de'
import { French } from 'flatpickr/dist/l10n/fr'
import gut from '@/service/gut'
import vorsorge from '@/service/vorsorge'
import beleg from '@/service/beleg'
import bwt from '@/service/bwtafel' // Beispiel um die Barwerttaflen zu benutzen um die Rente zu kapitalisieren...
import PdfViewer from '../../compo/PdfViewer.vue'

Vue.use(ModalPlugin)

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BFormCheckbox,
    BFormFile,
    BFormTextarea,
    // VueSlider,
    flatPickr,
    vSelect,
    BTable,
    BPagination,
    PdfViewer,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    const eigentum = [
      { value: 'ef', label: (this.$g.user.Sprache === 'fr' ? 'Biens propres' : 'Eigengut ') + this.$root.VNameFrau },
      { value: 'rf', label: (this.$g.user.Sprache === 'fr' ? 'Biens aqcquis' : 'Errungenschaft ') + this.$root.VNameFrau },
      { value: 'em', label: (this.$g.user.Sprache === 'fr' ? 'Biens propres' : 'Eigengut ') + this.$root.VNameMann },
      { value: 'rm', label: (this.$g.user.Sprache === 'fr' ? 'Biens aqcquis' : 'Errungenschaft ') + this.$root.VNameMann },
      { value: 'me', label: this.$g.user.Sprache === 'fr' ? 'Copropriété' : 'Miteigentum' },
    ]

    const flussArt = [
      { value: 'inv', label: this.$g.user.Sprache === 'fr' ? 'Investissement' : 'Investition/Erhöhung' },
      { value: 'rzd', label: this.$g.user.Sprache === 'fr' ? 'Remboursement' : 'Rückzahlung' },
      { value: 'kla', label: this.$g.user.Sprache === 'fr' ? 'Prestation en capital incapacité de travail' : 'Kapitalleistung Arbeitsunfähigkeit' },
      { value: 'klp', label: this.$g.user.Sprache === 'fr' ? 'Prestation en capital retraite' : 'Kapitalleistung Pensionierung' },
      { value: 'klv', label: this.$g.user.Sprache === 'fr' ? 'Prestation en capital prévoyance' : 'Kapitalleistung Vorsorgeeinrichtung' },
      // { value: 'rza', label: this.$g.user.Sprache === 'fr' ? 'Amortisation' : 'Amortisation' },
    ]

    const flussArtWEF = [
      { value: 'inv', label: this.$g.user.Sprache === 'fr' ? 'Investissement' : 'Investition' },
      { value: 'rzw', label: this.$g.user.Sprache === 'fr' ? 'Rembouresement EPL' : 'Rückzahlung WEF' },
      { value: 'enw', label: this.$g.user.Sprache === 'fr' ? 'Déclassement EPL' : 'Entwidmung WEF' },
      { value: 'rzd', label: this.$g.user.Sprache === 'fr' ? 'Remboursement prêts' : 'Rückzahlung Darlehen' },
      { value: 'rza', label: this.$g.user.Sprache === 'fr' ? 'Amortissement hypothèque' : 'Amortisation Hypothek' },
    ]

    const transGM = [
      { value: 'def', label: this.$g.user.Sprache === 'fr' ? 'Proprietaire' : 'Anteile Gütermassen' },
      { value: 'ef ', label: (this.$g.user.Sprache === 'fr' ? 'Biens propres' : 'Eigengut ') + this.$root.VNameFrau },
      { value: 'rf ', label: (this.$g.user.Sprache === 'fr' ? 'Biens aqcquis' : 'Errungenschaft ') + this.$root.VNameFrau },
      { value: 'em ', label: (this.$g.user.Sprache === 'fr' ? 'Biens propres' : 'Eigengut ') + this.$root.VNameMann },
      { value: 'rm ', label: (this.$g.user.Sprache === 'fr' ? 'Biens aqcquis' : 'Errungenschaft ') + this.$root.VNameMann },
    ]

    return {
      transGM,
      eigentum,
      flussArt,
      flussArtWEF,
      modalIDBeleg: 'Beleg',
      modalLiegenschaft: 'Liegenschaft',
      modalErweitert: 'Erweitert',
      isEditing: false,
      gu: null,
      gus: null,
      be: null,
      lg: null,
      ge: null,
      hi: null,
      vi: null,
      file: null,
      currPage: 1,
      pageRows: 10,
      maxFileSize: 5000000,
      modalHeight: window.innerHeight - 300,
      isCalc: false,
      modalID: 'gut',
      pdfIn: null,
      dateiName: null,
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'j M, Y',
        altInput: true,
        allowInput: true,
        // dateFormat: 'd.m.Y',
        locale: this.$g.user.BenSprache === 'fr' ? French : German,
      },
      fieldsGut: [
        { key: 'Gut', label: 'Vermögenswert/Schulden', sortable: true },
        { key: 'EigentumBez', label: 'Eigentum', sortable: true },
        { key: 'Betrag', formatter: value => { return value === undefined || value === null ? 0 : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'") }, label: 'Betrag', sortable: true, thClass: 'text-right', tdClass: 'text-right' }, // eslint-disable-line
      ],
      fieldsGutFluss: [
        { key: 'DatumTbl', label: 'Datum', sortable: true },
        { key: 'Beschrieb', label: 'Transaktion', sortable: true },
        { key: 'Betrag', formatter: value => { return value === undefined || value === null ? 0 : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'") }, label: 'Betrag', sortable: true }, // eslint-disable-line
        { key: 'DeleteGutFluss', label: '' },
      ],
      belegTyp: {
        Einlage: 1,
      },
      currBelegTyp: 1,
      currRow: null,
      currRowBeleg: null,
      currRowWEFTrans: null,
      currRowGE: null,
    }
  },
  async created() {
    // console.log(this.$root.currDosID)Musterdorf
    await this.loadGut(-1)
    const ri = await vorsorge.getVorsorgeInfo(this.$root.currDosID)
    if (ri.data.length > 0) this.vi = ri.data[0]
  },
  methods: {
    formatNumber(value) {
      if (!this.isEditing && typeof value === 'string') {
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
        return value
      }
      return false
    },
    formatBetrag(val) {
      let v = val.replaceAll(/[’']/g, '')
      v = Intl.NumberFormat('de-CH').format(v)
      return v
    },
    applyFormatGU(event, row, value) { // eslint-disable-line
      event.target.value = (event.target.value || '0').replace(/^0+(?!$)/, '').replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
      let ix = 0
      for (; ix < this.gu.length; ix++) if (this.gu[ix].GutID === row.item.GutID) break // eslint-disable-line
      this.gu[ix][value] = event.target.value
      this.isEditing = false
    },
    applyFormat(event, row, value) { // eslint-disable-line
      event.target.value = (event.target.value || '0').replace(/^0+(?!$)/, '').replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
      let ix = 0
      for (; ix < this.ge.length; ix++) if (this.ge[ix].GutFlussID === row.item.GutFlussID) break // eslint-disable-line
      this.ge[ix][value] = event.target.value
      this.isEditing = false
    },
    applyFormatLG(event, value) { // eslint-disable-line
      event.target.value = (event.target.value || '0').replace(/^0+(?!$)/, '').replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
      this.lg[value] = event.target.value
      this.isEditing = false
    },
    removeFormat(event) {
      this.isEditing = true
      event.target.value = event.target.value.replace(/[^\d]/g, '') // eslint-disable-line
      if (event.target.value === '0') event.target.value = '' // eslint-disable-line
    },
    async showGut(items) {
      if (items.length > 0) this.currRow = items[0]
      if (items.length > 0 && this.currRow._showDetails !== undefined) this.currRow._showDetails = !this.currRow._showDetails  // eslint-disable-line
    },

    async speichernGut() {
      let i = 0
      for (i = 0; this.currRow !== null && i < this.gu.length; i++) if (this.gu[i].GutID === this.currRow.GutID) break // eslint-disable-line
      await gut.putGut(this.gu)
      this.loadGut(i)

      this.$root.showToast('Vermögenswert/Fremdkapital', 'Vermögenswert/Fremdkapital wurde erfolgreich gespeicher!')
    },

    addGut() {
      const np = JSON.parse(JSON.stringify(this.gu)).slice(0, 1)
      np[0].GutID = -(this.gu.length + 1)
      np[0].Gut = ''
      np[0].Eigentum = 'rf'
      np[0].Betrag = 0
      np[0].GutLiegenschaftID = null
      np[0].MEProzFrau = 0
      np[0].MEZuordnung = null
      np[0].IstSchuld = 0
      np[0].LastGutID = null
      np[0].Bemerkung = null
      np[0].DosBelegID = null
      np[0].GeVerProzFrau = 0
      np[0].AnspruchEF = 0
      np[0].AnspruchRF = 0
      np[0].AnspruchEM = 0
      np[0].AnspruchRM = 0
      np[0].AnspruchME = 0
      np[0].MinderwertE = 0
      np[0].MinderwertR = 0
      // np[0]._showDetails = true // eslint-disable-line
      this.gu.push(np[0])
      this.$refs.tblGU.selectRow(this.gu.length - 1)
      this.currRow = this.gu[this.gu.length - 1]
      this.currRow._showDetails = true // eslint-disable-line
      const lastPage = Math.floor((this.gu.length - 1) / this.pageRows) + 1
      if (this.currPage !== lastPage) this.currPage = lastPage
    },

    async loadGut(i2Show) {
      const rg = await gut.getGut(this.$root.currDosID, this.$root.VNameFrau, this.$root.VNameMann)
      if (rg.data.length > 0) {
        for (let i = 0; i < rg.data.length; i += 1) rg.data[i]._showDetails = (i === i2Show) // eslint-disable-line
        this.gu = rg.data
        if (i2Show !== 0) this.currRow = this.gu[i2Show]
      }
      this.loadGutSel('')
    },

    async loadGutSel(eigentum) {
      const rg = await gut.getGutSel(this.$root.currDosID, eigentum)
      if (rg.data.length > 0) this.gus = rg.data
    },

    async deleteGut(item) {
      if (await this.$root.msgBoxConfirm('Soll Vermögenswert/Fremdkapital wirklich gelöscht werden?')) {
        await gut.delGut(this.$root.currDosID, item.GutID, item.DosBelegID)

        this.loadGut(-1)

        this.$root.showToast('Vermögenswert/Fremdkapital', 'Vermögenswert/Fremdkapital ' + item.Gut + ' wurde erfolgreich gelöscht!')
      }
    },

    async handleBeleg(belegTyp, item) { // set current belegType for save, get beleg and show it in the modal...
      this.currBelegTyp = belegTyp
      switch (belegTyp) {
        case this.belegTyp.Einlage: {
          this.currRowBeleg = item
          const re = await beleg.getDossierBeleg(item.DosBelegID === null ? -1 : item.DosBelegID, this.$root.currDosID, item.DosBelegID === null ? 17 : item.DosBelegID, this.$g.user.BenSprache)
          if (re.data.length > 0) {
            this.be = re.data[0]
            this.be.Bemerkung = this.be.Bemerkung === null ? `Beleg Güterrecht ${this.istMutter ? this.$root.VNameFrau : this.$root.VNameMann}` : this.be.Bemerkung
            this.be.Von = (item.Eigentum === 'ef' || item.Eigentum === 'rf') ? 3 : 4 // gemeinsames Eigentum noch zu regeln...
            this.be.IstGericht = 1
          }
          break
        } default:
      }

      this.$bvModal.show(this.modalIDBeleg)
      await this.$nextTick() // Trick to get $refs not to be undefined...
    },

    async speichernBeleg(bvModalEvent) { // save the beleg and set the DosBelegID...
      bvModalEvent.preventDefault()

      const r = await beleg.putDossierBeleg(this.be)

      switch (this.currBelegTyp) {
        case this.belegTyp.Einlage: {
          if (r != null && this.currRow.DosBelegID === null) {
            this.currRow.DosBelegID = r.data[0]
            this.be.DosBelegID = r.data[0]
            this.speichernGut()
          }
          break
        } default:
      }

      if (this.file !== null) await beleg.postBelegDatei(this.$root.currDosID, this.file, this.be.DosBelegID)
      this.$root.showToast('Beleg speichern', 'Beleg wurde erfolgreich gespeichert!')

      this.$nextTick(() => {
        this.$bvModal.hide(this.modalIDBeleg)
      })
    },
    async downloadBeleg() {
      const r = await beleg.getBelegDatei(this.$root.currDosID, this.be.DosBelegID, this.be.Mime)
      const fileURL = window.URL.createObjectURL(r.data)
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', this.be.DateiName)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    async deleteBeleg() {
      if (await this.$root.msgBoxConfirm('Soll Beleg gelöscht werden?')) {
        this.currRow.DosBelegID = null
        await gut.putGut(this.gu)

        await beleg.deleteBeleg(this.$root.currDosID, this.be.DosBelegID)

        this.$root.showToast('Gut-Transaktion', 'Transakation wurde erfolgreich gelöscht!')

        this.$nextTick(() => {
          this.$bvModal.hide(this.modalIDBeleg)
        })
      }
    },

    async handleLiegenschaft(item) {
      const rl = await gut.getLiegenschaft(item.LiegenschaftID ?? 0, this.$root.currDosID)
      if (rl.data !== null && rl.data.length > 0) this.lg = rl.data[0]

      this.$bvModal.show(this.modalLiegenschaft)
      await this.$nextTick() // Trick to get $refs not to be undefined...
    },
    async speichernLiegenschaft(bvModalEvent) {
      bvModalEvent.preventDefault()

      const r = await gut.putLiegenschaft(this.lg, this.currRow.GutID)

      if (r != null && this.currRow.LiegenschaftID === null) { // When new, LiegenschaftID is set in SPROC in Gut...
        this.currRow.LiegenschaftID = r.data[0]
        this.lg.LiegenschaftID = r.data[0]
        await this.speichernGut()
      }

      this.$nextTick(() => {
        this.$bvModal.hide(this.modalLiegenschaft)
      })
    },

    async showGutErweitert(items) {
      if (items.length > 0) this.currRowGE = items[0]
      this.currRowGE._showDetails = !this.currRowGE._showDetails  // eslint-disable-line
    },
    async handleErweitert(item) {
      const re = await gut.getGutFluss(item.GutID, this.$g.user.Sprache)
      if (re.data.length > 0) {
        // for (let i = 0; i < re.data.length; i += 1) re.data[i]._showDetails = (i === 0) // eslint-disable-line
        for (let i = 0; i < re.data.length; i += 1) re.data[i]._showDetails = false // eslint-disable-line
        this.ge = re.data
      }

      this.$bvModal.show(this.modalErweitert)
      await this.$nextTick() // Trick to get $refs not to be undefined...
    },
    addGutFluss() {
      const np = JSON.parse(JSON.stringify(this.ge)).slice(0, 1)
      np[0].GutFlussID = -(this.ge.length + 1)
      np[0].Datum = null
      np[0].FlussArt = 'inv'
      np[0].TransGM = 'def'
      np[0].Beschrieb = null
      np[0].Betrag = 0
      np[0].Rueckzahlung = 0
      np[0].IstEntgeltlichE = 0
      np[0].IstEntgeltlichR = 0
      np[0].IstKonjunkturell = true
      np[0].AnteilhEF = 0
      np[0].AnteilhRF = 0
      np[0].AnteilEM = 0
      np[0].AnnteilRM = 0
      np[0].AnteilME = 0
      np[0].AnteilLast1 = 0
      np[0].AnteilLast2 = 0
      np[0]._showDetails = true // eslint-disable-line
      this.ge.push(np[0])
      this.currRowGE = this.ge[this.ge.length - 1]
    },
    // async speichernGutFluss() {
    //   await gut.putGutFluss(this.ge)
    // },
    async speichernErweitert() {
      await gut.putGutFluss(this.ge)
    },

    async speichernErweitertSchliessen(bvModalEvent) {
      bvModalEvent.preventDefault()

      await gut.putGutFluss(this.ge)

      this.$nextTick(() => {
        this.$bvModal.hide(this.modalErweitert)
      })
    },

    async deleteGutFluss(item) {
      if (await this.$root.msgBoxConfirm('Soll Transaktion gelöscht werden?')) {
        await gut.delGutFluss(item.GutFlussID)

        const re = await gut.getGutFluss(item.GutID, this.$g.user.Sprache)
        if (re.data.length > 0) {
          for (let i = 0; i < re.data.length; i += 1) re.data[i]._showDetails = false // eslint-disable-line
          this.ge = re.data
        }

        this.$root.showToast('Gut-Transaktion', 'Transakation wurde erfolgreich gelöscht!')
      }
    },

    async last1Changed() {
    // async last1Changed(item) {
      // if (item.FlussArt === 'rzd') {
      //   if (this.currRow.Eigentum === 'ef') this.currRowGE.AnteilEF = item.AnteilLast1
      //   if (this.currRow.Eigentum === 'rf') this.currRowGE.AnteilRF = item.AnteilLast1
      //   if (this.currRow.Eigentum === 'em') this.currRowGE.AnteilEM = item.AnteilLast1
      //   if (this.currRow.Eigentum === 'rm') this.currRowGE.AnteilRM = item.AnteilLast1
      // }
    },

    async berechnenVorschlag() {
      this.isCalc = true
      await gut.calcVorschlag(this.$root.currDosID, this.$g.user.Sprache)
      this.isCalc = false
    },

    async setBarwert(item, istFrau) {
      if (item.FlussArt !== 'kla') return
      if (istFrau) item.AnteilEF = this.getBarwert(item.Betrag, istFrau ? item.AnteilEF : item.AnteilEM) // eslint-disable-line
      else item.AnteilEM = this.getBarwert(item.Betrag, istFrau ? item.AnteilEF : item.AnteilEM) // eslint-disable-line
    },
    getBarwert(rentenausfall, kapitalwert) {
      let rente = (rentenausfall ?? '0').toString().replaceAll(/[’']/g, '')
      let kwert = (kapitalwert ?? '0').toString().replaceAll(/[’']/g, '')
      rente = rente === null || rente === '' ? 0 : parseInt(rente, 10)
      kwert = kwert === null || kwert === '' ? 0 : parseInt(kwert, 10)
      if (this.istMutter === 1) return Intl.NumberFormat('de-CH').format((kwert === null || kwert === 0) ? Math.round(bwt.bwfFrau[this.vi.FrauAlterScheidung]['zs3.5'] * rente) : kwert)
      return Intl.NumberFormat('de-CH').format((kwert === null || kwert === 0) ? Math.round(bwt.bwfMann[this.vi.MannAlterScheidung]['zs3.5'] * rente) : kwert)
    },

    async showGutPdf() {
      this.isCalc = true
      const rp = await gut.getGutPdf(this.$root.currDosID, this.currRow.GutID)
      if (rp !== null) {
        this.dateiName = 'Gut.pdf'
        this.pdfIn = rp.data
        this.$refs.pv.show('gut')
      }
      this.isCalc = false
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
