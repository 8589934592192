<template>
  <div v-if="(!ag || !gr)">Loading Please wait...
  </div>
  <div v-else>
    <b-form>

      <b-table
        :items="ag"
        primary-key="GutID"
        :fields="fields"
        :current-page="currPage"
        :per-page="pageRows"
        striped
        hover
        responsive
        class="position-relative"
      >
        <template v-slot:cell(Gut)="row">
          {{ row.item.Gut }}
        </template>
        <template v-slot:cell(IstEigentumChange)="row">
          <b-form-checkbox
            v-model="row.item.IstEigentumChange"
            class="custom-control-primary"
            name="check-button"
            switch
            @change="calcBetrag(row)"
          />
        </template>
        <template v-slot:cell(BetragChange)="row">
          <b-form-input v-facade="'########'" :formatter="formatNumber" v-model="row.item.BetragChange" :disabled="true" class="text-right"
            @blur="applyFormat($event, row, 'BetragChange')"
            @focus="removeFormat"
          />
        </template>
      </b-table>

      <b-row>
        <b-col cols="12" md="3">
          <b-pagination
            v-model="currPage"
            :total-rows="ag.length"
            :per-page="pageRows"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
        <!-- <b-col cols="12" md="3" offset-md="3" text-right> -->
        <b-col cols="12" md="3">
          <div class="text-right">
            <b> {{ 'Ausgleichbetrag: ' + formatBetrag(gr.AusgleichSum.toString()) }} </b>
          </div>
        </b-col>
        <b-col cols="12" md="3">
          <div class="text-right">
            <b> {{ 'Zugewiesen: ' + formatBetrag(sumChange.toString()) }} </b>
          </div>
        </b-col>
        <b-col cols="12" md="3">
          <div class="mr-3 text-right">
            <b> {{ 'Differenz: ' + formatBetrag(gr.AusgleichDiff.toString()) }} </b>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-2">
          <b-col cols="12" md="12">
            <b-form-group
              label="Formulierung Vorgehen Ausgleich für Konvention"
              label-for="konvention"
            >
              <b-form-textarea
                v-model="gr.Konvention"
                rows="2"
                max-rows="3"
              />
            </b-form-group>
          </b-col>
      </b-row>

      <b-overlay :show="isCalc" no-wrap></b-overlay>  <!-- obscures row content, otherwise enclose content to be obscured... -->
      <b-row>
        <b-col cols="12" md="2">
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="save_changes"
          >
            Speichern
          </b-button>
        </b-col>
        <b-col cols="12" md="8">
          <div class="mb-1 text-right">
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="genGA"
            >
            Güterrechtliche Auseinandersetzung generieren
            </b-button>
          </div>
        </b-col>
        <b-col cols="12" md="2">
          <div class="mb-1 text-right">
            <b-button
              :disabled="gr.DosBelegID === null"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="showGA"
            >
            Anzeigen
            </b-button>
          </div>
        </b-col>
      </b-row>

    </b-form>

    <div v-show="false">
      <pdf-viewer
        ref="pv"
        :modal-i-d="modalID"
        :pdf-in="pdfIn"
        :file-name="dateiName"
      />
    </div>

  </div>
</template>

<script>

import {
  BTable, BRow, BCol, BButton, BFormInput, BForm, BFormGroup, BFormTextarea, BFormCheckbox, BPagination, BOverlay,
} from 'bootstrap-vue'
import db from '@/service/gut'
import beleg from '@/service/beleg'
import PdfViewer from '../../compo/PdfViewer.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BForm,
    BFormCheckbox,
    BPagination,
    PdfViewer,
    BOverlay,
  },
  data() {
    return {
      ag: null,
      gr: null,
      isEditing: false,
      pdfIn: null,
      dateiName: null,
      currPage: 1,
      pageRows: 5,
      sumChange: 0,
      isCalc: false,
      modalID: 'ausgleich',
      fields: [
        { key: 'Gut', label: 'Vermögenswert/Schuld' },
        { key: 'Eigentum', label: 'Gütermasse' },
        { key: 'IstEigentumChange', label: 'Besitzer zugewiesen' },
        { key: 'BetragChange', label: 'Betrag', thClass: 'text-right' }, // eslint-disable-line
      ],
    }
  },
  async mounted() {
    this.$root.$refs.GUTAUSGLEICH = this

    await this.fetchAusgleich()
  },
  methods: {
    async fetchAusgleich() {
      const r = await db.getGutAusgleich(this.$root.currDosID)
      for (let i = 0; i < r.data.length; i += 1) {
        r.data[i].SelBetrag = r.data[i].BetragChange
        this.sumChange += r.data[i].IstEigentumChange ? r.data[i].BetragChange : 0
      }
      if (r.data.length > 0) this.ag = r.data

      const rr = await db.getGueterrecht(this.$root.currDosID)
      if (rr.data.length > 0) this.gr = rr.data[0]

      this.gr.AusgleichDiff = this.gr.AusgleichSum === null ? 0 : this.gr.AusgleichSum - this.sumChange
    },
    formatNumber(value) {
      if (!this.isEditing && typeof value === 'string') {
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
        return value
      }
      return false
    },
    applyFormat(event, row, value) { // eslint-disable-line
      event.target.value = (event.target.value || '0').replace(/^0+(?!$)/, '').replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
      let ix = 0
      for (; ix < this.data.length; ix++) if (this.data[ix].GutID === row.item.GutID) break // eslint-disable-line
      this.data[ix][value] = event.target.value
      this.isEditing = false
    },
    removeFormat(event) {
      this.isEditing = true
      event.target.value = event.target.value.replace(/[^\d]/g, '') // eslint-disable-line
      if (event.target.value === '0') event.target.value = '' // eslint-disable-line
    },
    formatBetrag(val) {
      let v = val.replaceAll(/[’']/g, '')
      v = Intl.NumberFormat('de-CH').format(v)
      return v
    },
    async calcBetrag(row) {
      if (row.item.IstEigentumChange) this.sumChange += row.item.SelBetrag
      else this.sumChange -= row.item.SelBetrag
      this.gr.AusgleichDiff = this.gr.AusgleichSum - this.sumChange
    },
    async save_changes() {
      await db.putGutAusgleich(this.ag)
      await db.putGueterrechtAusgleich(this.$root.currDosID, this.gr.AusgleichDiff, this.gr.Konvention)
      this.$root.showToast('Ausgleich', 'Ausgleich wurde erfolgreich gespeicher!')
    },
    async genGA() {
      this.isCalc = true
      await db.calcVorschlag(this.$root.currDosID, this.$g.user.Sprache)
      const rr = await db.getGueterrecht(this.$root.currDosID)
      if (rr.data.length > 0) this.gr = rr.data[0]

      this.isCalc = false
      this.$root.showToast('Güterrechtliche Auseinandersetzung', 'Güterrechtliche Auseinandersetzung wurde erfolgreich generiert!')
    },
    async showGA() {
      const rb = await beleg.getBelegDatei(this.$root.currDosID, this.gr.DosBelegID)
      if (rb !== null) {
        this.dateiName = 'Güterrechtliche-Auseinandersetzun.pdf'
        this.pdfIn = rb.data
        this.$refs.pv.show()
      }
    },
  },
}
</script>
