<template>
  <b-tabs v-model="activeTab" @activate-tab="onTabChange"
    pills
  >
    <b-tab active>
      <template #title>
        <feather-icon
          icon="ClipboardIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Vermögenswerte/Schulden</span>
      </template>
      <Gut
        class="mt-2 pt-75"
      />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="CopyIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Hinzurechnungen</span>
      </template>
      <Hinzurechnung
        class="mt-2 pt-75"
      />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="DivideCircleIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Ausgleich</span>
      </template>
      <Ausgleich
        class="mt-2 pt-75"
      />
    </b-tab>

  </b-tabs>

</template>

<script>
import {
  BTab, BTabs,
} from 'bootstrap-vue'
import Gut from './Gut.vue'
import Hinzurechnung from './Hinzurechnung.vue'
import Ausgleich from './Ausgleich.vue'

export default {
  components: {
    BTab,
    BTabs,
    Gut,
    Hinzurechnung,
    Ausgleich,
  },
  data() {
    return {
      activeTab: 0,
    }
  },
  async mounted() {
    // this.$root.$refs.Grunddaten = this // Register to reload data when changing number of kidz in Allgemein...
  },
  methods: {
    // IMPORTANT: TO MAKE THE AUTOSAVE WORK, WINDOW.SAVEFORMDATA MUST MERELY BE SET IN THE FIRST TAB-FORM, OTHERWISE, IT'S NOT DEFINED WHICH FUNCTION-POINTER IS SET...
    async onTabChange(newIx) {
      if (newIx === 2) await this.$root.$refs.GUTAUSGLEICH.fetchAusgleich()
      this.activeTab = newIx
    },
  },
}
</script>

<style>

</style>
